<template>
  <div class="page">
    <Carousel type="home"></Carousel>
    <div class="cont-box">
      <div class="container">
        <div class="title-box">
          <div class="title1"><img src="~@/assets/img/logo1.png" alt="" /></div>
          <div class="title2">
            <span class="titles">{{$t('public.OurProducts')}}</span>
            <!-- <img src="~@/assets/img/title-list1.png" alt="" /> -->
          </div>
        </div>
        <ProductList type="home"></ProductList>
        <div class="btn-box">
          <div class="btn-box-text" @click="learn">{{$t('public.LearnMore')}}
            <img src="~@/assets/img/arr.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="cont-box">
      <div class="">
        <div class="title-box">
          <div class="title1"><img src="~@/assets/img/logo1.png" alt="" /></div>
          <div class="title2">
            <span class="titles">{{$t('public.aboutUs')}}</span>
            <!-- <img src="~@/assets/img/title-list2.png" alt="" /> -->
          </div>
        </div>
        <div>
          <!-- <img src="~@/assets/img/home/about1.png" alt=""> -->
          <img :src="imgUrl + infoDeta.homeUsImg" alt="" style="width: 100%">
        </div>
        <div class="about">
          <div class="left">
            <!-- <img src="~@/assets/img/home/about2.png" alt=""> -->
            <img :src="imgUrl + infoDeta.homeUsImg2" alt="">
          </div>
          <div class="right">
            <div>
              <div class="us-text">
                {{$t('public.Wearefrom')}}
              </div>
              <div class="btn-box">
                <div @click="aboutus" class="btn-box-text">{{$t('public.LearnMore')}}
                  <img src="~@/assets/img/arr.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormInput></FormInput>
    <!-- <div class="cont-box">
      <div class="container">
        <div class="title-box">
          <div class="title1"><img src="~@/assets/img/logo1.png" alt="" /></div>
          <div class="title2">
            <img src="~@/assets/img/title-list2.png" alt="" />
          </div>
        </div>
        
        <el-form ref="form" :model="form" class="demo-form-inline">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="">
                    <el-input class="input-lst" v-model="form.name" placeholder="Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="">
                    <el-input class="input-lst" v-model="form.telephone" placeholder="Telephone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="">
                    <el-input class="input-lst" v-model="form.mail" placeholder="E-mail"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="">
                    <el-input type="textarea" :rows="8" class="input-lst input-textarea" v-model="form.mail" placeholder="Your information"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-form-item style="text-align: center;">
              <div class="btn-box">
                <div class="btn-box-text" @click="onSubmit">submit to
                  <img src="~@/assets/img/arr.png" alt="">
                </div>
              </div>
            </el-form-item>
        </el-form>
      </div>
    </div> -->
    
    
  </div>
</template>

<script>
// import { publicData } from "@/minxins/public";

import { getAction } from "@/api/manage";
export default {
  // mixins: [publicData],
  data() {
    return {
      current: 1,
      size: 5,
      total: 1,
      tagList: [],
      value1: "",
      form: {
        name: ''
      },
      tableData: [],
      formlogin: {},

      carouselList: [
        // {
        //   img: require("@/assets/img/xiaomi.png"),
        //   text: "See you at FREAKOUSE",
        // },
        // {
        //   img: require('@/assets/img/xiaomi.png'),
        //   text: 'See you at FREAKOUSE'
        // },
      ],
      imgUrl: process.env.VUE_APP_BASE_IMG,
      infoDeta: {}
    };
  },
  created() {},
  mounted() {
    // this.init();
    // this.loadData();
    // this.$refs.ResourceInvocation.init();

    // this.thomeRotation() //首页轮播图

    this.infoDeta =JSON.parse(localStorage.getItem('infoDeta'))
  },
  methods: {
    learn() {
      this.$router.push({
        path: "/Products",
      });
    },
    aboutus() {
      this.$router.push({
        path: "/Aboutus",
      });
    },
    // thomeRotation() {
    //   getAction(
    //     process.env.VUE_APP_BASE_URL + "/t/thomeRotation/listData",
    //     {
    //       '__ajax':"json",
    //       '__login':false,
    //       'language': localStorage.getItem('lang')
    //     }
    //   ).then((res) => {
    //     console.log(res.data,111);
    //     this.carouselList = res.data.list;
        
    //   });
    // },
    onSubmit() {

    },
    SelectTime(time) {
      if (time == null) {
        this.form = {};
        this.loadData();
      } else {
        this.form.startTime = time[0];
        this.form.endTime = time[1];
        this.loadData();
      }
    },
    init() {
      getAction(process.env.VUE_APP_HOME_URL + "/log/statistics").then(
        (res) => {
          // getAction('http://192.168.119.249:8600/auc/aucIndex/count').then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            this.tagList = [
              {
                name: "访问量",
                num: data.fangwenliang,
              },
              {
                name: "访客数",
                num: data.fangkeshu,
              },
              {
                name: "访问次数",
                num: data.fangwencishu,
              },
              {
                name: "账号总数",
                num: data.zhanghaozongshu,
              },
              {
                name: "部门总数",
                num: data.bumenshu,
              },
              {
                name: "角色总数",
                num: data.jueseshu,
              },
            ];
          } else {
            this.$message.info(res.data.msg);
          }
        }
      );
      // getAction(process.env.VUE_APP_HOME_URL + 'log/log/indexLogCount').then((res) => {
      //   if (res.data.code == 200) {
      //     let data1 = res.data.data
      //     this.tagList.push(
      //       {
      //         name: '上周活跃用户',
      //         num: data1.sevenUser,
      //       }, {
      //       name: '昨日活跃用户',
      //       num: data1.yesterdayUser,
      //     }, {
      //       name: '上周登录系统总次数',
      //       num: data1.sevenLogin,
      //     }, {
      //       name: '昨日登录系统总次数',
      //       num: data1.yesterLogin,
      //     },
      //     )
      //   } else {
      //     this.$message.info(res.data.msg);
      //   }
      // });
    },

    loadData() {
      this.formlogin.current = this.current;
      this.formlogin.size = this.size;
      getAction(
        process.env.VUE_APP_HOME_URL + "/log/findPageList",
        this.formlogin
      ).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        } else {
          this.$message.info(res.data.msg);
        }
      });
    },
    // 获取本地图片
    // backImg(num) {
    //   return require("../../assets/HomePage/ellipse" + (num + 1) + ".png");
    // },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // width: 1400px;
  margin: 0 auto;
  .mar{
    margin-top: 50px;
  }
  .title-box {
    padding: 10px 0;
    text-align: center;
    .title1{
      img{width: 100px;}
    }
    .title2{
      img{width: 500px;}
    }
  }
  .cont-box {
    background: url("~@/assets/img/bg.png");
    background-size: 100% 100%;
    padding: 80px 0;
    .container{
      width: 1400px;
      margin: 0 auto;
    }
  }
  .plush{
    margin-top: 30px;
  }
  .plush-box {
    text-align: center;
    .plush-top {
      width: 280px;
      height: 280px;
      background-color: #fff;
      border-radius: 89px 10px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 140px;
      }
    }
    .plush-bot {
      cursor: pointer;
      width: 290px;
      height: 60px;
      line-height: 60px;
      // background-color: #e6e5e1;
      // color: #9d856b;
      font-size: 22px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 10px;
    }
  }
  .btn-box{
    text-align: center;
    margin-top: 40px;
    .btn-box-text{
      cursor: pointer;
      background: #9C846B;
      width: 220px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      color: #fff;
      border-radius: 5px;
      margin: 20px auto;
      position: relative;
      box-shadow: 2px 1px 4px #9C846B;
      transition: all 0.5s;
      img{
        position: absolute;
        right: 28px;
        top: 13px;
        width: 28px;
      }
    }
    .btn-box-text:hover{
      background: #aa967e;
    }
  }

  .about{
    display: flex;
    .left,.right{
      flex: 1;
    }
    .left{
      img{
        width: 100%;
      }
    }
    .right{
      display: flex;
      justify-content: center;
      // align-items: center;
      .us-text{
        margin-top: 150px;
        width: 639px;
        height: 172px;
        font-size: 30px;
        color: #9C846B;
        text-align: justify;
        // margin: 0 auto;
        line-height: 80px;
      }
      .btn-box{
        margin-top: 200px;
      }
    }
  }
  .input-lst{
    width: 630px;
  }
  .input-textarea{
    width: 90%;
  }


  .form-card {
    margin: 15px 0 10px;
  }
  .bannerBox {
    width: 1400px;
    height: 130px;
    margin: 0 auto;
    h1 {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      font-family: Source Han Sans CN;
      color: #000000;
    }

    .tag_setting {
      display: flex;
      justify-content: space-between;

      div {
        width: 12%;
        height: 97px;
        text-align: center;

        p:nth-child(1) {
          margin-top: 35px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }

        p:nth-child(2) {
          margin-top: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3a3a3a;
        }
      }
    }
  }

  .tabTitle {
    // width: 1400px;
    // height: 378px;
    background: #ffffff;
    border-radius: 4px;
  }

  .tabTitle1 {
    // margin-top: 13px;
    // width: 1400px;
    // height: 438px;

    .ChartAnalysis {
      display: flex;
      justify-content: space-between;

      .chart {
        width: 49.5%;
        height: 438px;
        background: #ffffff;
        border-radius: 4px;

        h1 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000000;
          text-align: center;
        }
      }
    }
  }

  .Ranking {
    margin-top: 13px;
    width: 1372px;
    min-height: 438px;
    background: #ffffff;
    padding: 14px;
    border-radius: 4px;

    .LabelSelection {
      display: flex;
      justify-content: space-between;
      height: 50px;
      border-bottom: 2px solid #e6e6e6;

      p {
        margin-top: 12px;
        margin-left: 20px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2a7ddf;
      }
    }
  }
}
</style>
<style scoped>
.input-lst >>> .el-input__inner, .input-lst >>> .el-textarea__inner{
  height: 108px;
  line-height: 108px;
  /* border: none; */
  border-radius: 17px;
  font-size: 32px;
  color: #D1C1AA;
  padding-left: 20px;
}
.input-lst >>> .el-textarea__inner{
  height: initial;
  line-height: initial;
}
.left >>> .el-carousel__indicators--horizontal {
  display: none;
}

.Ranking >>> .el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #0079e0;
  color: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
</style>